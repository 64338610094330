// IMPORTS
// =====================

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

// COLORS
// =====================
$background-cream: #FDF9EE;
$charcoal-dark: #171717;
$charcoal-medium: #575757;
$charcoal-light: #7B7B7B;
$charcoal-ultra-light: #C2C2C2;
$emphasis-red: #B6000F;
$purple: #6B81E5;

// GLOBALS
// =====================
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  color: var($background-cream);
  overflow-x: hidden;
  background: $charcoal-dark;
}

::-webkit-scrollbar {
  display: none;
}

a, input, textarea {
  all: unset;
  display: block;
}

* {
  box-sizing: border-box;
}

input {
  box-sizing: border-box;
  border: 1px solid black;
}

h1, h2, h3, h4, button, h6, p, hr, pre, a, input, span {
  margin: 0;
}

a, button {
  cursor: pointer;
}

hr {
  width: 100%;
}

input{
  font-size: 2rem;
  border: none;
  margin-top: 2rem;
  border-bottom: 1px solid $purple;
}

.yes-no{
  display: flex;
  flex-direction: column;
}

.continue{
  float: right;
  color: $purple;
  border-bottom: 1px solid $purple;
  text-align: right;
  min-width: 100px;
  font-size: 2rem;
  text-transform: uppercase;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6B81E550 ;
}

.Container {
  min-height: 100vh;
  background-color: $charcoal-dark;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.App {
  width: 100%;
  min-height: 100vh;
  padding-left: 64px;
  padding-right: 64px;
  color: $background-cream;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-spacer {
  flex: 1;
}

// COMPONENTS
// =====================

// HEADER
// =====================


.Header {
  display: flex;
  position: sticky;
  top: 5;
  flex-direction: row;
  justify-content: space-between;
    width: 100%;
  border-bottom: solid 1px $charcoal-light;
  margin: 5% auto;

  a{
    display: flex;
    flex-direction: row;
    vertical-align: middle;   

    .logo{
      font-weight: 100;
      text-transform: uppercase;
      letter-spacing: .08rem;
    }
 

    svg{
      margin-right: 10px;
      margin-top: 2px;
    }
  }

  .navigation-links {
    display: flex;
    flex-direction: row;
    
    .navigation-link {
      font-weight: 400;
      font-size: 16px;
      color: $charcoal-medium;
      &:not(:first-child) {
        margin-left: 22px;
      }
      &.selected {
        font-weight: 500;
        color: $background-cream;
      }
    }
  }
}

.sitemap {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .branding {
    img.logo {
      width: 160px;
    }
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
    
    .navigation-link {
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

// HERO HEADER
// =====================

.HeroHeader {
  position: fixed;
  display: absolute;
  top: 2rem;
  right: 3.70rem;

  a{
    display: flex;
    flex-direction: row;
    vertical-align: middle;   

    .logo{
      font-weight: 100;
      font-size: 3vw;
      text-transform: uppercase;
      letter-spacing: .08rem;
    }
 

    svg{
      margin-right: 1vw;
      margin-top: 2px;
    }
  }
}




.address-input {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;

  .address-input-text {
    text-align: center;
    width: 420px;
    margin-right: 20px;
  }

}

.input{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

button{
  font-family: 'Inter';
  color: $background-cream;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 8px;
  border: 0;
  background-color: transparent;

  svg{
    margin-left: .8rem;
  }
}


// SCREENS
// =====================
.screen{
  height: 100vh;
}

.collection-show-screen {
  p {
    color: black;
  }

  .holders {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    width: 500px;
    border: 2px solid black;
    border-radius: 5px;
    background-color: white;

    .holder-section {
      display: flex;
      width: 33.33%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        width: 100%;
        text-align: center;
      }
    }
  }

  .listings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

}

.collectible-listing-index {
  margin-top: 10px;
  &:not(:first-child) {
    &:not(:nth-child(4n + 1)) {
      margin-left: 10px;
    }
  }
  height: 345px;
  width: 236px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid black;
  border-radius: 4px;
  background-color: white;
  color: black;

  .collectible-name {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nft-image {
    height: 236px;
  }

  .listing-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    .listing-price-amount {
      font-size: 24px;
      font-weight: bold;
    }

    .listing-price-currency {
      margin-left: 2.5px;
    }

    .listing-price-usd {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .bottom-actions {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  }
}


// Template
// =====================


.template-container{
  border: solid white 1px;
  height: 100%;
  width: 100%;
  margin: auto;
}

.template-header {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .template-navigation-links {
    display: flex;
    flex-direction: row;
    
    .template-navigation-link {
      font-weight: 400;
      font-size: 16px;
      color: $charcoal-medium;
      &:not(:first-child) {
        margin-left: 22px;
      }
      &.selected {
        font-weight: 500;
        color: $background-cream;
      }
    }
  }
}

// LANDING PAGE SECTIONS
// =================


// HERO

#section1{
  border-bottom: 1px solid $charcoal-light;
}

.hero-text{
  font-size: 4.5rem;
  font-weight: 300;
  margin-top: 5rem;
  z-index: 100;
}

.hero-subcontainer{
  font-size: 2rem;
  font-weight: 100;
  margin-top: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  div{
    max-width: 36rem;

    span{
      color: $purple;
      font-weight: 400;
    }
  }
}


//section2

#section2{
  height:100vh;
  overflow: none;
  

  h1{
    font-size: 12vw;
    text-transform: uppercase;
    color: $charcoal-dark;
    text-shadow: -1px -1px 0 #6B81E550, 1px -1px 0 #6B81E550, -1px 1px 0 #6B81E510, 1px 1px 0 #6B81E505;
  }

  h3{
    font-size: 6.5vw;
    text-transform: uppercase;
    color: $background-cream;
    margin-bottom:10rem;
  }

  .grow { 
    transition: all .2s ease-in-out; 
    }
    
    .grow:hover { 
    transform: scale(1.2); 
    }

  h4{
    font-size: 3vw;
    font-weight: 200;
    text-transform: uppercase;
    color: $background-cream;
  }

  nav {
    display: flex;
    flex-direction: column;
    margin-top: 20rem;

  }
  
  nav > button {
    color: rgba(255, 255, 255, 0.25);
    font-size: clamp(3rem, 5vw, 10rem);
    font-weight: 100;
    text-decoration: none;
    position: relative;
    margin-bottom: 2rem;
    text-transform: uppercase;
    text-align: left;
    max-width: 40%;

  }
  
  nav > button:hover {
    color: white;
  }
  
  nav > button > span {
    display: block;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    position: relative;
    z-index: 2;
  }
  
  nav > button:hover > span {  
    border-top-color: white;
    border-bottom-color: white;
  }
  
  nav > button > .hover {
    position: absolute;
    opacity: 0;

    transition: transform 250ms, opacity 250ms;
    pointer-events: none;
  }

  nav > button > .hover > img{
    max-width: 40vw
  }
  
  nav > button:hover > .hover {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    text-align: right;
    color: $purple;
  }
  nav > button:hover > .hover > div {
z-index: 100;
  }

  .get-started{
    font-size: 6vw;
    font-weight: 400;
    float: right;
    white-space: nowrap;
    color: $purple;
    margin-top: 4rem;
    margin-bottom: 10rem;
    transition: all .2s ease-in-out; 
   
   svg{
     margin-left: 1rem;;
   }
   
   }
  


}

